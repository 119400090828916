<template>
  <div>
    <z-header white></z-header>
    <div class="case_box">
      <div class="page_center">
        <div class="case_list_box">
          <div class="case_list">
            <div class="case_content">
              <div class="case_name">{{ caseInfo.name }}</div>
              <div class="case_date">{{ caseInfo.onlineDate }}</div>
              <p>功能：{{ caseInfo.featuresDesc }}</p>
              <img
                v-if="caseInfo.shareImg"
                class="caseinfo_img"
                :src="caseInfo.shareImg"
              />
            </div>
            <div class="othercase_list_box">
              <router-link
                tag="div"
                v-for="(item, index) of caseList"
                :key="index"
                class="case_list"
                :to="'/caseDetails/' + item.objId"
              >
                <div class="image">
                  <img :src="item.headImg" :alt="item.name" />
                </div>
                <div class="case_type">{{ item.type }}</div>
                <div class="case_Introduction">
                  <div class="time">{{ item.onlineDate }}</div>
                  <div class="title">{{ item.name }}</div>
                </div>
              </router-link>
            </div>
          </div>
          <img class="case_img" :src="detailsInfo" />
        </div>
      </div>
    </div>
    <z-footer></z-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      caseList: [],
      caseInfo: {},
      detailsInfo: {},
    };
  },
  created() {
    let objId = this.$route.params.id;
    this.getDetail(objId);
    this.getInfo(objId);
    this.getMain();
  },
  methods: {
    onPageJump(url) {
      this.$router.push(url);
    },
    changeDetail(objId) {
      this.$router.replace("/caseDetails/" + objId);
      this.getDetail(objId);
      this.getInfo(objId);
      this.getMain();
    },
    getDetail(objId) {
      this.$http.get("api/case/case_detail?objId=" + objId).then((res) => {
        this.detailsInfo = res.data;
      });
    },
    getInfo(objId) {
      this.$http.get("api/case/v2/case_detail?objId=" + objId).then((res) => {
        this.caseInfo = res.data;
      });
    },
    getMain() {
      var httpData = {
        pageNo: 1,
        pageSize: 12,
      };
      this.$http.post("api/case/case_main", httpData).then((res) => {
        this.caseList = res.data.data;
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == from.name) {
      let objId = to.params.id;
      this.getDetail(objId);
      this.getInfo(objId);
      this.getMain();
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.case_box {
  background-color: #f7f7f7;
  .page_center {
    padding-top: 32px;
    .case_list_box {
      display: flex;
      justify-content: space-between;
      .case_list {
        .case_content {
          padding: 23px;
          background-color: #ffffff;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
          width: 290px;
          .caseinfo_img {
            margin-top: 23px;
            width: 120px;
            height: 120px;
          }
          .case_name {
            font-size: 24px;
            color: #333333;
          }
          .case_date {
            margin-top: 12px;
            font-size: 14px;
            color: #999999;
          }
          p {
            margin-top: 26px;
            font-size: 14px;
            color: #333333;
          }
        }
        .othercase_list_box {
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          .case_list {
            width: 290px;
            background-color: #ffffff;
            margin-right: 12px;
            margin-bottom: 12px;
            border-radius: 6px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            .image {
              width: 100%;
              height: 222px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .case_type {
              position: absolute;
              top: 12px;
              left: 15px;
              padding: 0 12px;
              height: 25px;
              background-color: rgba(0, 0, 0, 0.4);
              color: #fff;
              font-size: 14px;
              line-height: 25px;
            }
            .case_Introduction {
              padding: 23px 16px;
              background-color: #fff;
              .time {
                font-size: 14px;
                color: #999999;
              }
              .title {
                font-size: 18px;
                color: #333333;
                margin-top: 10px;
              }
              .features {
                font-size: 14px;
                color: #333333;
                margin-top: 20px;
                border-top: 1px solid #f7f7f7;
                padding-top: 20px;
              }
            }
          }
        }
      }
      .case_img {
        max-width: 876px;
        object-fit: cover;
        display: block;
        flex-shrink: 0;
        margin-bottom: 60px;
      }
    }
  }
}
</style>